import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { createLogger } from 'redux-logger';
import shouldStartWithEditor from './lib/shouldStartWithEditor';

import rootReducer from './reducers';
import { openEditModal } from './actions/editor';
import _ from 'lodash';

export const setUpNewStore = () => {
  const middleware = [thunk, createDebounce()];

  // The serverConfig will not be present when the measure editor is launched from storyteller
  if (!_.isEmpty(window.serverConfig) && window.serverConfig.environment === 'development') {
    middleware.push(
      createLogger({
        duration: true,
        timestamp: false,
        collapsed: true
      })
    );
  }

  const store = createStore(rootReducer, applyMiddleware(...middleware));

  // Call store.dispatch on any actions that need to occur on initial store load.
  // When the measure editor is launched from storyteller,
  // we don't need to check the coreView (which won't be present) or approvals.
  if (_.isEmpty(window.serverConfig)) {
    store.dispatch(openEditModal());
  } else {
    // This is set in frontend/app/helpers/measures_editor_helper.rb
    // using the `approvals_guidance` method from frontend/app/models/view.rb
    // which just calls the api endpoint `/api/views/${coreUid}/approvals?method=guidance_v2&assetId=${assetId}`
    const approvalsGuidanceJson = JSON.parse(window.socrata.measuresEditor.approvalsGuidance);
    if (shouldStartWithEditor(window.socrata.measuresEditor.coreView, approvalsGuidanceJson)) {
      store.dispatch(openEditModal());
    }
  }

  return store;
};

export default setUpNewStore();

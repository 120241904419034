import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

import MeasureResultCard from 'common/performance_measures/components/MeasureResultCard';
import MeasureChart from 'common/performance_measures/components/MeasureChart';
import TimelineScopeChooser from 'common/performance_measures/components/MeasureChart/TimelineScopeChooser';
import { Measure, MeasureMetadata } from 'common/performance_measures/types';
import { getTimelineScope } from 'common/performance_measures/lib/measureHelpers';
import { View } from 'common/types/view';

// @ts-expect-error
import AboutThisMeasure from './AboutThisMeasure';
import { TimelineScopes } from '../../performance_measures/lib/constants';

interface SummaryPaneProps {
  measure: Measure;
  coreView: View;
}

interface SummaryPaneState {
  lastMeasure: Measure;
  timelineScope: TimelineScopes;
}

/**
 * Pane containing the primary visual representations of the metric (value of
 * most recent reporting period + timeline), as well as prose information about
 * the methodological underpinnings of the measure.
 */
export class SummaryPane extends Component<SummaryPaneProps, SummaryPaneState> {
  constructor(props: SummaryPaneProps) {
    super(props);

    this.state = {
      lastMeasure: props.measure,
      timelineScope: getTimelineScope(props.measure)
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: SummaryPaneProps) {
    // The timelineScope state is ephemeral - loading a new measure should reset it to the value in
    // the measure config. To implement this, we must track the last seen measure.
    if (
      FeatureFlags.valueOrDefault('enable_global_filters_measures', false) &&
      this.state.lastMeasure !== newProps.measure
    ) {
      this.setState({
        lastMeasure: newProps.measure,
        timelineScope: getTimelineScope(newProps.measure)
      });
    }
  }

  renderScrollPane() {
    const { measure, coreView } = this.props;
    const { timelineScope } = this.state;

    if (!FeatureFlags.valueOrDefault('enable_global_filters_measures', false)) {
      const { shortName } = measure.metadata || {};
      const { name } = coreView || null;

      return (
        <div className="scroll-pane pre-forge-scroll-pane">
          <h2 className="scroll-pane-title">{shortName || name}</h2>

          <div className="scroll-pane-content">
            <div id="latest-metric">
              <MeasureResultCard measure={measure} isInsituMeasure={false} />
            </div>

            <div id="metric-visualization">
              {/* Timeline scope will be ignored when enable_global_filters_measures is false */}
              <MeasureChart measure={measure} isInsituMeasure={false} timelineScope={TimelineScopes.ALL} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="scroll-pane">
        <div className="scroll-pane-content-upper">
          <TimelineScopeChooser
            measure={measure}
            timelineScope={timelineScope}
            setTimelineScope={(scope: TimelineScopes) => this.setState({ timelineScope: scope })}
          />
        </div>
        <div className="scroll-pane-content">
          <div id="latest-metric">
            <MeasureResultCard
              measure={measure}
              showMetadata={true}
              isInsituMeasure={false}
              lens={coreView}
            />
          </div>

          <div id="metric-visualization">
            <MeasureChart measure={measure} timelineScope={timelineScope} isInsituMeasure={false} />
          </div>
        </div>
      </div>
    );
  }

  renderMethodsAndAnalysis() {
    const metadata = _.get(this.props.measure, 'metadata', {} as MeasureMetadata);

    const isUnconfigured = !metadata?.methods && !metadata?.analysis;

    const containerClasses = classNames('methods-and-analysis', {
      unconfigured: isUnconfigured
    });

    return (
      <div className={containerClasses}>
        <h3>{I18n.t('shared.measures_editor.measure.methods_and_analysis.combined')}</h3>

        {_.map(['methods', 'analysis'], (sectionName) => {
          const sectionContents = metadata[sectionName];
          if (!sectionContents) {
            return null;
          }

          return [
            <h5 key={`${sectionName}-header`}>
              {I18n.t(`shared.measures_editor.measure.methods_and_analysis.${sectionName}`)}
            </h5>,
            <p key={`${sectionName}-contents`}>{sectionContents}</p>
          ];
        })}

        {isUnconfigured && <p>{I18n.t('shared.measures_editor.measure.methods_and_analysis.placeholder')}</p>}
      </div>
    );
  }

  render() {
    return (
      <div className="pane" data-pane="summary">
        <div className="summary-pane-upper">{this.renderScrollPane()}</div>

        <div className="summary-pane-lower">
          <AboutThisMeasure />
          {this.renderMethodsAndAnalysis()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: { view: { measure: Measure; coreView: View } }) {
  return state.view;
}

export default connect(mapStateToProps)(SummaryPane);

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import store, { setUpNewStore } from './store';

import I18n from 'common/i18n';

import MeasuresAssetActionBar from './components/MeasuresAssetActionBar';
import EditModal from './components/EditModal/EditModal';
import InfoPane from './components/InfoPane';
import PaneTabs from './components/PaneTabs';
import SummaryPane from './components/SummaryPane';
import MetadataPane from './components/MetadataPane';
import ErrorMessageTemplate from 'common/components/ErrorMessageTemplate';
import './styles/measuresEditor.scss';
import { assertIsNotNil } from 'common/assertions';
import { fetchDataSourceView } from './actions/editor';
import withComputedMeasure from 'common/performance_measures/components/withComputedMeasure';
import computedMeasurePropType from 'common/performance_measures/propTypes/computedMeasurePropType';

// Outermost component for the page.
export class App extends Component {
  constructor(props) {
    super(props);
    if (props.isInsitu) {
      this.store = setUpNewStore();
    }
  }

  componentDidMount() {
    const { canEdit } = this.props;
    this.setConfirmationDialogue();
    if (canEdit) {
      document.body.classList.add('hide-site-chrome');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isModified !== this.props.isModified) {
      this.setConfirmationDialogue();
    }
  }

  setConfirmationDialogue() {
    const { isModified } = this.props;

    if (isModified) {
      window.addEventListener('beforeunload', this.handleConfirm);
    } else {
      window.removeEventListener('beforeunload', this.handleConfirm);
    }
  }

  handleConfirm(e) {
    const confirmationMessage = I18n.t('shared.measures_editor.save_prompt');
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  renderInfoPane() {
    const { activePane, isEditing } = this.props;
    // The info pane is expensive to render (it contains charts etc),
    // so don't render it if the edit modal will cover it up anyway.
    if (isEditing) {
      return null;
    }

    return (
      <div>
        <InfoPane />
        <div className="measure-content">
          <div className="measure-panes">
            <PaneTabs />
            {activePane === 'summary' && <SummaryPane />}
            {activePane === 'metadata' && <MetadataPane />}
          </div>
        </div>
      </div>
    );
  }
  onCompleteInsitu = (measure) => {
    assertIsNotNil(this.props.onComplete);
    this.props.onComplete({
      measure: measure
    });
  };
  fetchView = async (uid) => {
    try {
      const datasource = await this.store.dispatch(fetchDataSourceView(uid));

      return datasource;
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { isEditing, isInsitu, measure, onCancel, computedMeasure } = this.props;

    if (isInsitu) {
      let dataSourceView;
      setTimeout(() => {
        dataSourceView = this.fetchView(this.props.measure.dataSourceLensUid);
      }, 1000);
      return (
        <Provider store={this.store}>
          <div className="measure-body">
            {isEditing && (
              <EditModal
                onCancel={onCancel}
                onComplete={this.onCompleteInsitu}
                isInsitu={isInsitu}
                measure={measure}
                dataSourceView={dataSourceView}
              />
            )}
          </div>
        </Provider>
      );
    }

    const { dataSourcePermissionDenied } = _.get(computedMeasure, 'errors', {});
    if (dataSourcePermissionDenied) {
      return <ErrorMessageTemplate dataStatus="403" />;
    }

    return (
      <Provider store={store}>
        <div className="measure-body">
          <MeasuresAssetActionBar />
          {this.renderInfoPane()}
          {isEditing && <EditModal isInsitu={isInsitu} />}
        </div>
      </Provider>
    );
  }
}

App.propTypes = {
  activePane: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isInsitu: PropTypes.bool,
  isModified: PropTypes.bool,
  measure: PropTypes.object,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  computedMeasure: computedMeasurePropType
};

App.defaultProps = {
  activePane: 'summary',
  isInsitu: false,
  onComplete: _.noop,
  onCancel: _.noop,
  computedMeasure: {
    errors: {}
  }
};

function mapStateToProps(state) {
  const { activePane, canEdit } = state.view;
  const { coreView, isEditing, pristineCoreView, pristineMeasure } = state.editor;
  const measure = isEditing ? state.editor.measure : state.view.measure;
  const isModified =
    isEditing && (!_.isEqual(measure, pristineMeasure) || !_.isEqual(coreView, pristineCoreView));
  return { activePane, canEdit, isEditing, isModified, measure };
}

const wrappedApp = withComputedMeasure({ lastPeriodOnly: true })(App);
export default connect(mapStateToProps)(wrappedApp);
